import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player'
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './App.css';
import volvo from './img/volvo.png';
import logo from './img/logo.png';
import rabaty from './img/rabat.png';
import car from './img/ciezarowka.png';
import hero from './img/hero.png';
import finger from './img/finger.png';
import carmask from './img/car-mask.jpg';
import phone from './img/tel.png';
import dlaczego from './img/dlaczego.png';
import oswietlenietext from './img/oswietlenie-text.png';
import gallery from './img/gallery.jpg';
import slide from './img/slide.png';
import film from './img/film.png';
import filmtext from './img/film-text.png';
import g1 from './img/g1.png';
import g2 from './img/g2.png';
import g3 from './img/g3.png';
import p1 from './img/p1.jpg';
import p2 from './img/p2.jpg';
import p3 from './img/p3.jpg';
import p4 from './img/p4.jpg';
import p5 from './img/p5.jpg';
import p6 from './img/p6.jpg';
import p7 from './img/p7.jpg';
import p8 from './img/p8.jpg';
import p9 from './img/p9.jpg';
import p10 from './img/p10.jpg';
import videoCover from './img/video-img-cover.jpg';
import play from './img/play.png';
import line1 from './img/line1.png';
import line2 from './img/line2.png';
import filmHeader from './img/filmheader.png';
import filmHeader2 from './img/filmheaderbottom.png';
import volvoVideo from './img/volvo.webm'


function App() {
  const [webHeight, setWebHeight] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const updateHeight = () => {
      const webElement = document.querySelector('.web');
      if (webElement) {
        setWebHeight(webElement.offsetHeight);
      }
    };

    // Zmierz wysokość po załadowaniu całego okna
    window.addEventListener('load', updateHeight);

    // Aktualizuj podczas zmiany rozmiaru
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('load', updateHeight);
      window.removeEventListener('resize', updateHeight);
    };
  }, []);


  return (
    <div className='web'>
      <img className='logo-volvo' src={volvo} />
      <div className='border' style={{ height: `${webHeight - 110}px`}}></div>
      
      {/* Zawartość komponentu */}
      <div className="header">
        <div className='main-block'>
          <div className='logos'>
            {/* <div><img src={logo} alt="Logo" /></div> */}
            <div><img src={rabaty} alt="Rabaty" /></div>
          </div>
          <div className='hero'>
            <img className='line1' src={line1} alt="line1" />
            <p>Oferta specjalna na wszystkie lampy w <b>technologii LED: przednie, robocze, boczne</b> oraz <b>tylne i ostrzegawcze</b>!</p>
            <img className='line2' src={line2} alt="line2" />
          </div>
          <div className='hero-space'>
            <img src={carmask} width={'95%'} alt="Car mask" />
          </div>
          <a className='zamow' href="https://www.volvoce.com/polska/pl-pl/contact-us/" target='_blank'>
            ZADZWOŃ I ZAMÓW
            <img className='finger' src={finger} alt="Finger" />
            <div className='tel'>
              <div><img src={phone} alt="Phone" /></div>
              <div>508 508 167</div>
            </div>
          </a>
          <div className='info'>
            PRZEJDŹ NA <span>TECHNOLOGIE LED</span> Z VOLVO!
          </div>
          <p>Orginalne systemy oświetlenia Volvo są projektowane, testowane i dostosowywane przez Volvo.
            <b> To najlepsza gwarancja jakości na miarę Twoich oczekiwań! Teraz w najlepszych cenach!</b>
          </p>
        </div>
        <div className='car'></div>
      </div>

      {/* Pozostałe sekcje */}
      <div className="section1">
        <div className='content'>
          <div className='slide'>
            <Zoom>
              <img
                src={g1}
                alt="Photo 1"
                style={{ width: '100%', cursor: 'pointer'}}
              />
            </Zoom>
            <Zoom>
              <img
                src={g2}
                alt="Photo 2"
                style={{ width: '100%', cursor: 'pointer' }}
              />
            </Zoom>
            <Zoom>
              <img
                src={g3}
                alt="Photo 3"
                style={{ width: '100%', cursor: 'pointer' }}
              />
            </Zoom>
          </div>
        </div>
      </div>

      <div className="section2">
        <div className='content'>
          <h3>Dlaczego warto wybrać oświetlenie ledowe Volvo?</h3>
          <ul>
            <li>Prosty i szybki montaż.</li>
            <li>2 lata gwarancji.</li>
            <li>Technologia najwyższej klasy.</li>
            <li>Bardzo długa żywotność.</li>
            <li>Niskie zużycie energii.</li>
            <li>Najwyższa trwałość i efektywność działania w wymagających warunkach.</li>
            <li>Indywidualne konsultacje z Ekspertami Volvo w doborze oświetlenia.</li>
          </ul>
        </div>
      </div>

      <div className="section3">
        <div className='content'>
          <h2>Oświetlenie VOLVO zobacz różnicę</h2>
          <p>
            Oświetlenie maszyny decyduje o bezpieczeństwie, wydajności i skuteczności pracy w słabych warunkach oświetleniowych. 
            <br/><br/>
            <b>Oryginalne oświetlenie Volvo — zobacz różnicę.</b><br/><br/>
            Volvo oferuje szeroką gamę wysokiej klasy rozwiązań w dziedzinie oświetlenia, m.in. świateł roboczych, reflektorów i świateł ostrzegawczych, dostępnych w postaci różnych źródeł światła — halogenowych, ksenonowych i LED — odpowiadających zróżnicowanym potrzebom i preferencjom.
            Nieoryginalne zamienniki świateł mogą mieć niższą jakość i być w większym stopniu narażone na uszkodzenia czy usterki, a tym samym wydłużać ewentualne przestoje. Mogą też zużywać dużo energii, niepotrzebnie obciążając w ten sposób akumulator i układy elektryczne. Oryginalne systemy oświetlenia Volvo są projektowane, testowane i dostosowywane przez Volvo
          </p>
        </div>
      </div>

      <div className="section4">
        <div className='gallery'>

            <div>
              <Zoom>
                <img
                  src={p1}
                  alt="Photo 1"
                  style={{ width: 'auto', cursor: 'pointer'}}
                />
              </Zoom>  
              Światła robocze         
            </div>
            <div>
              <Zoom>
                <img
                  src={p2}
                  alt="Photo 1"
                  style={{ width: '100%', cursor: 'pointer'}}
                />
              </Zoom>  
              REFLEKTORY PRZEDNIE          
            </div>
            <div>
              <Zoom>
                <img
                  src={p3}
                  alt="Photo 1"
                  style={{ width: '100%', cursor: 'pointer'}}
                />
              </Zoom>  
              ŚWIATŁA OSTRZEGAWCZE          
            </div>
            <div>
              <Zoom>
                <img
                  src={p4}
                  alt="Photo 1"
                  style={{ width: '100%', cursor: 'pointer'}}
                />
              </Zoom>  
              ŚWIATŁA TYLNE          
            </div>
            <div>
              <Zoom>
                <img
                  src={p5}
                  alt="Photo 1"
                  style={{ width: '100%', cursor: 'pointer'}}
                />
              </Zoom> 
              ŚWIATŁA NAWIGACYJNE           
            </div>
    

        
            <div>
              <Zoom>
                <img
                  src={p6}
                  alt="Photo 1"
                  style={{ width: '100%', cursor: 'pointer'}}
                />
              </Zoom>  
              OŚWIETLENIE WNĘTRZA          
            </div>
            <div>
              <Zoom>
                <img
                  src={p7}
                  alt="Photo 1"
                  style={{ width: '100%', cursor: 'pointer'}}
                />
              </Zoom>   
              REFLEKTORY         
            </div>
            <div>
              <Zoom>
                <img
                  src={p8}
                  alt="Photo 1"
                  style={{ width: '100%', cursor: 'pointer'}}
                />
              </Zoom>
              OŚWIETLENIE PRZYRZĄDÓW            
            </div>
            <div>
              <Zoom>
                <img
                  src={p9}
                  alt="Photo 1"
                  style={{ width: '100%', cursor: 'pointer'}}
                />
              </Zoom> 
              INNE RODZAJE ŚWIATEŁ           
            </div>
            <div>
              <Zoom>
                <img
                  src={p10}
                  alt="Photo 1"
                  style={{ width: '100%', cursor: 'pointer'}}
                />
              </Zoom> 
              ŻARÓWKI           
            </div>            



        </div>
      </div>

      <div className="section5">
        <div className='main-block'>
          <img className='film-header' src={filmHeader} alt=''/>
          <div className='video-box' onClick={() => setIsPlaying(true)}>
            <ReactPlayer
              className='react-player'
              url={volvoVideo}
              width='100%'
              height='100%'
              light={videoCover}
              playing={isPlaying}
              controls={true}
              playIcon={
                <button
                  style={{ background: 'transparent', border: 0, cursor: 'pointer' }}
                  onClick={() => setIsPlaying(true)}
                >
                  <img src={play} alt='' />
                </button>
              }
            />   
                     
          </div>
          <img className='film-header-bottom' src={filmHeader2} alt=''/>
          <div className='footer-points'>
            <h3 style={{textAlign: 'left'}}>Cechy wspólne systemów oświetlenia Volvo.</h3>
            <ul>
              <li>Jasność, temperatura barwowa i wzór świetlny zgodne z normami.</li>
              <li>Przygotowane pod kątem trudnych warunków pracy.</li>
              <li>Klasa ochrony: IP68 – IP69K.</li>
              <li>Testy EMC klasy 5.</li>
              <li>Volvo — ekspert w integracji systemów.</li>
              <li>Gwarancja Volvo.</li>
              <li>Bezpieczne dla oczu operatorów.</li>
              <li>Wyjątkowa wysoka odporność na drgania, pył, wilgoć́ i wstrząsy mechaniczne.</li>
              <li>Pełna ochrona przed przedostawaniem się kurzu oraz gorącej wody pod ciśnieniem.</li>
              <li>Zatwierdzone przez producenta dopasowanie do układów elektrycznych i mechanicznych.</li>
              <li>Brak zakłóceń elektromagnetycznych.</li>
            </ul>           
          </div>

   
          <a className='zamow' href="https://www.volvoce.com/polska/pl-pl/contact-us/"target='_blank'>
            ZADZWOŃ I ZAMÓW
            <img className='finger' src={finger} alt="Finger" />

            <div className='tel' style={{textDecoration: 'none'}}>
              <div><img src={phone} alt="Phone" /></div>
              <div>508 508 167</div>
            </div>
          </a>


        </div>
      </div>

    </div>
  );
}

export default App;
